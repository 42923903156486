<template>
	<div>
		<el-form :inline="true" class="demo-form-inline">
			<el-form-item><el-input v-model="templateName" size="small" placeholder="请输入模板名称"></el-input></el-form-item>
			<el-form-item><el-button type="primary" size="small" @click="search">查询</el-button></el-form-item>
		</el-form>
		<el-table border ref="singleTable" :data="tableData" highlight-current-row :row-key="getRowKey" @selection-change="handleCurrentChange" style="width: 100%">
			<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
			<el-table-column prop="couponTemplateName" label="模板名称"></el-table-column>
		</el-table>
		<el-pagination small :page-size="pageSize" layout="prev, pager, next" @current-change="changePage" :total="total"></el-pagination>
	</div>
</template>

<script>
export default {
	props: {
		selectdata: {
			type: Object,
			default: () => {}
		},
		formconfig: {
			type: Object,
			default: () => {}
		},
		opentype: {
			//类型判断0自定义 1为新建，2为编辑，3为查看
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			tableData: [],
			page: 1,
			pageSize: 4,
			total: 0,
			templateName:""
		};
	},
	created() {
		this.getProductList();
		this.arr = this.selectdata.associatedCouponId ? this.selectdata.associatedCouponId.split(',') : [];
		// console.log(this.selectdata)
	},
	methods: {
		getRowKey(row) {
			return row.couponTemplateId;
		},
		search(){
			this.page = 1;
			this.getProductList();
		},
		handleCurrentChange(val) {
			if (val) {
				let ids = [],
					names = [];
				val.map(v => {
					ids.push(v.couponTemplateId);
					names.push(v.couponTemplateName);
				});
				ids = Array.from(new Set(ids));
				names = Array.from(new Set(names));
				this.$emit('getVal', { val: ids.toString(), name: names.toString() });
			} else {
				this.$emit('getVal', { val: '', name: '' });
			}
		},
		changePage(e) {
			this.page = e;
			this.getProductList();
		},
		async getProductList() {
			let params = {
				pageNum: this.page,
				pageSize: this.pageSize
			}
			if(this.templateName){
				params.couponTemplateName = this.templateName
			}
			const res = await this.$axios('post','/sys/couponTemplate/Page',params,true
			);
			const { records, total } = res;
			this.total = parseInt(total);
			this.tableData = records || [];
			this.arr.map(v => {
				this.tableData.forEach(row => {
					if (v == row.couponTemplateId) this.$refs.singleTable.toggleRowSelection(row);
				});
			});
		}
	}
};
</script>

<style lang="scss">
	.demo-form-inline{
		text-align: right;
		margin: 10px 0 5px;
		padding: 0;
		/deep/.el-form-item{
			margin: 0;
		}
	}
</style>
